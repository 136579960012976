import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import "@runners/editor/dist/style.css"
import { ImageResize } from "@runners/editor/src/_extensions/image-resize"
import { VideoPreview, Youtube, StepTemplate, AdminLinkPreview } from "@runners/editor-extensions"
import Color from "@tiptap/extension-color"
import Image from "@tiptap/extension-image"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import { EditorContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { useGetGuideQuery } from "@/apis/guideApi"

const TipsDetailPage = () => {
  const { id } = useParams<{ id: string }>()
  const guideId = Number(id)

  if (isNaN(guideId) || guideId <= 0) {
    return <p className="py-20 text-center text-red-500">잘못된 가이드 ID입니다.</p>
  }

  const { data: guide, isLoading, isError } = useGetGuideQuery(guideId, { enabled: !!guideId })

  const editor = useEditor({
    editable: false,
    // content: "",
    content: guide?.data.content || "",
    extensions: [
      StarterKit,
      VideoPreview,
      AdminLinkPreview,
      Youtube,
      StepTemplate,
      Text,
      TextStyle,
      Color,
      Image.configure({ inline: true, allowBase64: true }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      ImageResize,
    ],
  })

  // useEffect(() => {
  //   if (editor && guide?.data.content) {
  //     editor.commands.setContent(guide?.data.content)
  //   }
  // }, [editor, guide?.data.content])
  // 동기화이슈
  useEffect(() => {
    if (editor && guide?.data.content) {
      Promise.resolve().then(() => {
        editor.commands.setContent(guide.data.content)
      })
    }
  }, [editor, guide?.data.content])

  // useEffect(() => {
  //   const updateEditorContent = async () => {
  //     if (editor && guide?.data.content) {
  //       await editor.commands.setContent(guide.data.content)
  //     }
  //   }
  //   updateEditorContent()
  // }, [editor, guide?.data.content])

  if (isLoading) return <p className="py-20 text-center">가이드를 불러오는 중입니다...</p>
  if (isError || !guide || !guide.data.content) {
    return <p className="py-20 text-center text-red-600">가이드를 불러올 수 없습니다.</p>
  }

  return (
    <div className="mx-auto w-[1080px] px-5 py-20 mobile:w-[335px]">
      <h1 className="mb-[100px] text-center text-34 font-bold text-[#21222E] mobile:mb-[60px]">{guide?.data.title}</h1>
      <EditorContent className="[&_p]:min-h-4" editor={editor} />
    </div>
  )
}

export default TipsDetailPage
