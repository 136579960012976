import { Ordering } from "@runners/interface"
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query"
import api, { METHOD } from "@/apis"
import { DjangoListResponse, DjangoResponse } from "./storeApi"

export interface GuideListDto {
  id: number
  type: "TIP"
  sub_type: string
  title: string
  thumbnail_image_url?: string
  mobile_thumbnail_image_url?: string
  content?: string
}

export type GuideDto = {
  id: number
  title: string
  content: string
  type: "TIP"
  sub_type?: string
}

export type AdminGuideListParams = {
  type?: string
  sub_type?: string
  search?: string
  ordering?: Ordering
}

export const guideQueryKey = {
  guide: () => ["guide"],
  guideList: (params?: AdminGuideListParams) => ["guide", "list", { ...params }],
  guideDetail: ({ guideId }: { guideId: number }) => ["guide", "detail", guideId],
}

const guideApi = {
  getGuideList: async (params: AdminGuideListParams) => {
    const { data } = await api.get<DjangoListResponse<GuideListDto>>(`/guides`, {
      method: METHOD.GET,
      params,
    })
    return data
  },

  getGuide: async ({ guideId }: { guideId: number }) => {
    const { data } = await api.get<DjangoResponse<GuideDto>>(`/guide/${guideId}`)
    return data
  },
}

export const useGetGuideListQuery = (params: AdminGuideListParams) => {
  return useQuery({
    queryKey: guideQueryKey.guideList(params),
    queryFn: () => guideApi.getGuideList(params),
    select: response => response.data,
  })
}

// export const useGetGuideQuery = (guideId: number, queryOptions: Omit<UseQueryOptions, "queryKey" | "queryFn">) => {
//   return useQuery({
//     queryKey: ["guide", guideId],
//     queryFn: () => guideApi.getGuide({ guideId }),
//     ...queryOptions,
//   })
// }

export const useGetGuideQuery = (
  guideId: number,
  queryOptions: Omit<UseQueryOptions<DjangoResponse<GuideDto>>, "queryKey" | "queryFn">,
): UseQueryResult<DjangoResponse<GuideDto>> => {
  return useQuery({
    queryKey: ["guide", guideId],
    queryFn: () => guideApi.getGuide({ guideId }),
    ...queryOptions,
  })
}

export default guideApi
