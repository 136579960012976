import { useState } from "react"
import "@runners/editor/dist/style.css"
import { useGetNoticeListQuery } from "@/apis/noticeApi"
import NoticeContent from "./detail"

type NoticeCategory = "전체" | "일반 공지" | "프로모션"

const categories: NoticeCategory[] = ["전체", "일반 공지", "프로모션"]

const allCategoryType: Record<NoticeCategory, string> = {
  전체: "",
  "일반 공지": "GENERAL",
  프로모션: "PROMOTION",
}

const NoticePage = () => {
  const [activeCategory, setActiveCategory] = useState<NoticeCategory>("전체")
  const { data: noticeData, isLoading } = useGetNoticeListQuery({
    type: allCategoryType[activeCategory],
  })

  const handleCategoryClick = (category: NoticeCategory) => setActiveCategory(category)

  return (
    <div className="mx-auto flex w-[1080px] flex-col py-20 mobile:w-[375px] mobile:px-5 mobile:py-10">
      <h1 className="mb-[100px] text-center text-3xl font-bold text-[#21222E] mobile:mb-[60px]">공지사항</h1>

      <div className="mb-10 flex items-center justify-center gap-4 mobile:mb-6">
        {categories.map(category => (
          <div key={category} className="w-[140px] mobile:w-auto">
            <button
              className={`w-full rounded-full border border-solid border-[#E8E8F0] px-3 py-2 text-16 font-medium ${
                activeCategory === category ? "bg-[#424350] font-bold text-white" : "bg-white text-gray-700"
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          </div>
        ))}
      </div>

      {isLoading ? (
        <></>
      ) : (
        <div className="space-y-4">
          {noticeData?.data && noticeData.data.length > 0 ? (
            noticeData.data.map(({ id, title }) => <NoticeContent key={id} noticeId={id} title={title} />)
          ) : (
            <div className="text-center text-gray-500">해당 카테고리에 공지사항이 없습니다.</div>
          )}
        </div>
      )}
    </div>
  )
}

export default NoticePage
