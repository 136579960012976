import React, { useEffect, useState } from "react"
import { ImageResize } from "@runners/editor/src/_extensions/image-resize"
import { AdminLinkPreview, VideoPreview } from "@runners/editor-extensions"
import Color from "@tiptap/extension-color"
import TextStyle from "@tiptap/extension-text-style"
import { EditorContent, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { useGetNoticeQuery } from "@/apis/noticeApi"
import { CaretUpIcon } from "@/assets/icons"
import cn from "@/utils/cn"

const NoticeContent = ({ noticeId, title }: { noticeId: number; title: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { data: guideData } = useGetNoticeQuery(noticeId, {
    enabled: isExpanded,
  })

  const editor = useEditor({
    editable: false,
    content: guideData?.data.content || "",
    extensions: [TextStyle, Color, StarterKit, VideoPreview, AdminLinkPreview, ImageResize],
  })

  useEffect(() => {
    if (editor && guideData?.data.content) {
      Promise.resolve().then(() => {
        editor.commands.setContent(guideData.data.content)
      })
    }
  }, [editor, guideData?.data.content])
  return (
    <div
      className={cn(
        "cursor-pointer rounded-3 bg-gray-100 px-5 py-6 transition-all active:bg-gray-200 md:hover:bg-gray-200",
        "w-[1080px] mobile:w-[335px]",
      )}
      onClick={() => {
        setIsExpanded(prev => !prev)
      }}
    >
      <div className="flex items-center justify-between">
        <p className="text-16 font-bold md:text-18">{title}</p>
        <CaretUpIcon
          className={cn("size-6 transition duration-300 ease-in-out", {
            "rotate-180": !isExpanded,
          })}
        />
      </div>

      {isExpanded && (
        <div className="transition-all duration-300 ease-in-out">
          <div className="mt-5 [&_p]:min-h-4" />
          <EditorContent editor={editor} />
        </div>
      )}
    </div>
  )
}

export default NoticeContent
