import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import StepTemplateComponent from "./component"

export const StepTemplate = Node.create({
  name: "guide-step-template",

  group: "block",

  addAttributes() {
    return {
      stepNumber: {
        default: "",
      },
      title: {
        default: "",
      },
      content: {
        default: "",
      },
      image: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "guide-step-template",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["guide-step-template", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(StepTemplateComponent)
  },
})
